
export const constant = {
  NAME: 'Name',
  LIVE_RANKING: 'Live Ranking',
  POINTS: 'Points',
  VIEW_ALL: 'View All',
  GROWTH_CHART: 'Growth Chart',
  HIKE: 'Hike %',
  SCORE_STREAK: 'Score Streak',
  SCORE: 'Score',
  SELECT_YEAR: 'Select Year',
  ALL_YEARS: 'Select Year',
  YEAR: 'Year',
  MONTH: 'Month',
  SCORING: 'Scoring',
  CERTIFICATES: 'Certificates',
  TRAINING: 'Training',
  PAGE_NOT_FOUND_CODE: '404',
  PAGE_NOT_FOUND_MESSAGE: 'Page Not Found',
  VIEW: 'View',
  DESCRIPTION: 'description',
  DESCRIPTION_LIMIT: 2000,
  MAX_CHAR_LIMIT: 'You have reached your maximum limit of 2000 characters allowed',
};

export const categories = {
  ALL: 'All',
  DIRECT_REPORTING: 'Direct Reporting',
  BU: 'BU',
  PROJECT: 'Project Team',
  OTHERS: 'Others',
};

export const authorizedPath = {
  SUPERADMIN: ['/company-artifacts'],
};

export const employeeArtifact = [
  {
    status: 'pending',
    certificates: 'angular basic',
    uploadeDate: '11/15/23',
    duration: '5 months',
    points: 125,
    img: '/Frame 48097277.png',
  },
  {
    status: 'approved',
    certificates: 'angular basic',
    uploadeDate: '11/15/23',
    duration: '5 months',
    points: 125,
    img: '/Frame 48097277.png',
  },
  {
    status: 'rejected',
    certificates: 'angular basic',
    uploadeDate: '11/15/23',
    duration: '5 months',
    points: 125,
    img: '/Frame 48097277.png',
  },
];

export const status = ['Pending', 'Approved', 'Rejected', 'PartiallyApproved'];
export const defaultLevelColumn = ['Status', 'Artifact Name', 'Approver', 'Approved/Rejected By', 'Points', 'Action'];
export const projectLevelColumn = ['Status', 'Artifact Name', 'Duration', 'Approver', 'Approved/Rejected By', 'Points', 'Action'];
export const defaultViewLevelColumn = ['Artifact Name', 'Approver', 'Approved By', 'Status', 'Points', 'Attachments'];
export const specialCategoryLevelColumn = ['Status', 'Artifact Name', 'Primary Approver', 'Secondory Approver', 'Approved/Rejected By', 'Points', 'Action'];
export const viewCloneColumn = ['Name', 'Designation', 'Action'];
export const showCloneColumn = ['Name', 'Designation'];

export const employees = [
  {
    name: 'John Smith',
    designation: 'Business Analyst',
    points: 150,
    certificates: 12,
    training: 10,
    rankDifference: +1,
  },
  {
    name: 'Emily Johnson',
    designation: 'Project Manager',
    points: 250,
    certificates: 12,
    training: 10,
    rankDifference: -3,
  },
  {
    name: 'Michael Davis',
    designation: 'Software Engineer',
    points: 180,
    certificates: 3,
    training: 17,
    rankDifference: +2,
  },
  {
    name: 'Sarah Thompson',
    designation: 'HR Manager',
    points: 220,
    certificates: 10,
    training: 10,
    rankDifference: -1,
  },
  {
    name: 'Robert Wilson',
    designation: 'Marketing Specialist',
    points: 190,
    certificates: 3,
    training: 10,
    rankDifference: +4,
  },
  {
    name: 'Jennifer Lee',
    designation: 'Data Analyst',
    points: 170,
    certificates: 5,
    training: 10,
    rankDifference: -2,
  },
  {
    name: 'David Brown',
    designation: 'Product Manager',
    points: 210,
    certificates: 12,
    training: 6,
    rankDifference: +3,
  },
  {
    name: 'Jessica Clark',
    designation: 'Financial Analyst',
    points: 240,
    certificates: 15,
    training: 5,
    rankDifference: -1,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Andrew Taylor',
    designation: 'Sales Representative',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  // {
  //   name: 'Olivia Martinez',
  //   designation: 'Operations Manager',
  //   points: 200,
  //   rankDifference: -2,
  // },
];

export const employees2 = [
  {
    name: 'Alice Johnson',
    designation: 'Software Engineer',
    department: 'PHP JS',
    points: 180,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Bob Thompson',
    designation: 'Product Manager',
    department: 'Business Operations',
    points: 220,
    certificates: 23,
    training: 43,
    rankDifference: -1,
  },
  {
    name: 'Charlie Brown',
    designation: 'Business Analyst',
    department: 'PHP JS',
    points: 150,
    certificates: 19,
    training: 1,
    rankDifference: +1,
  },
  {
    name: 'David Smith',
    designation: 'Data Analyst',
    department: 'Frontend',
    points: 190,
    certificates: 7,
    training: 4,
    rankDifference: -2,
  },
  {
    name: 'Eva Davis',
    designation: 'HR Manager',
    department: 'Talent Acquisition',
    points: 240,
    certificates: 2,
    training: 2,
    rankDifference: -1,
  },
  {
    name: 'Frank Wilson',
    designation: 'Marketing Specialist',
    department: 'Cloud and DevOps',
    points: 200,
    certificates: 12,
    training: 10,
    rankDifference: +4,
  },
  {
    name: 'Grace Taylor',
    designation: 'Sales Representative',
    department: 'Mobility',
    points: 170,
    certificates: 15,
    training: 11,
    rankDifference: -2,
  },
  {
    name: 'Henry Martinez',
    designation: 'Financial Analyst',
    department: 'Net',
    points: 250,
    certificates: 4,
    training: 8,
    rankDifference: -3,
  },
  {
    name: 'Ivy Clark',
    designation: 'Project Manager',
    department: 'Accounts & Admin',
    points: 210,
    certificates: 10,
    training: 5,
    rankDifference: +3,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Quality Analysis',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Successive AI',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
  {
    name: 'Jack Lee',
    designation: 'Operations Manager',
    department: 'Mobility',
    points: 160,
    certificates: 12,
    training: 10,
    rankDifference: +2,
  },
];

export const addArtifactsConstants = {
  artifact_name_info: 'Please enter Artifact Name',
  select_project_info: 'Please select a Project',
  project_manager_info: 'Based on Project selected above',
  select_subcategory_info: 'Please select a Subcategory',
  add_url_info: 'Please enter a valid URL',
  project_name_info: 'Please select a Project Name',
  project_status_info: 'Please specify if you are currently working on this project or not',
  project_duration_info: 'If currently working on this project, please select start date. If not, please select a duration',
  artifact_description_info: 'Please enter Artifact Description highlighting details about the Artifact',
  artifact_table_info: 'Please enter Feedback details about the Artifact',
  upload_images_info: 'Please upload a supporting document. Only images and PDF formats are allowed',
  select_line_item_info: 'Please select a Line Item',
  points_info: 'Points',
  file_error_1: 'File(s) with invalid extensions',
  file_error_2: 'File Size cannot be more than 5MB',
  file_error_3: 'You cannot upload more than 5 Files',
  file_error_4: '1 or more files already exists',
  file_error_5: 'Invalid file(s). File type can only be image or pdf',
  add_title: 'Add Artifacts',
  edit_title: 'Edit Artifacts',
  artifcat_name: 'Artifact Name*',
  project_name: 'Project Name*',
  project_manager: 'Project Manager/ Account Manager*',
  artifact_subcategory: 'Artifact Subcategory*',
  line_item: 'Line Item*',
  url: 'URL',
  project: 'Project Name*',
  currently_project: 'Currently working on this project?*',
  duration: 'Your Duration*',
  artifacts_description: 'Artifact Description*',
  artifacts_table: 'Table*',
  upload_document: 'Upload Document*',
  upload_document_feedback: 'Upload Document',
  drag_drop: 'Drag and drop Certificate files',
  choose_files: 'Choose files',
  preview: 'Preview',
  draft: 'Save as draft',
  submit: 'Submit',
  quarter: 'Quarter',
  rating: 'rating',
  Description: 'Description',
  Area_of_Improvement: 'Area of Improvement',
};
