/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Alert,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ProjectData } from './type';
import Toast from '../../../components/Toast/Toast';
import { ToastProps } from '../../../components/Toast/types';
import { validateFields } from './validate';
import {
  mapSeverityToAlertColor, getCurrentYear, getAuthHeader, getCurrentQuarter,
} from '../../../utils/utils';
import { config } from '../../../config';
import { CommHandler } from '../../../utils';
import { FormWrapper } from './form.styled';
import { MessageAlert } from '../../../components/MessageAlert';
import MenuAppraisalCycle from '../../Profile/components/MenuAppraisalCycle';
import CancelConfirmDialoge from './CancelConfirmDialoge';
import SubmitButton from './SubmitButton';
import { cardContentStyle } from '../Artifact.styled';
import AddProductLevelForm from './addProductlevelForm';

const AddProjectLevelArtifact = () => {
  const location = useLocation();
  const { id: artifactFormId } = useParams();
  const isEditPage = location.pathname.includes('editArtifact');
  const navigate = useNavigate();
  const {
    text: artifactName, id: selectedCatId, categoryCode: artifactCatCode, categoryId: artifactCatId,
  } = useParams();
  const projectLevelDataInterface: ProjectData = {
    artifact_category_id: '',
    artifact_sub_category_id: '',
    artifact_item_id: '',
    name: '',
    file_path: [],
    user_id: '',
    approved_by: null,
    year: '',
    from_date: '',
    to_date: '',
    project_manager_id: null,
    description: '',
    areaOfImprovement: '',
    is_draft: false,
    project_working_status: false,
    achieved_points: null,
    project_name: '',
    files: [],
    type: '',
    selectedlineItem: '',
    url: '',
    project_id: '',
    project_manager: [],
    financial_year: '',
    preFilePath: [],
    preFiles: [],
    quarter: null,
    rating: null,
  };
  const [data, setData] = useState<ProjectData[]>([projectLevelDataInterface]);
  const [selectedYear, setSelectedYear] = useState<string | null>(getCurrentYear().toString());
  const handleYearSelect = (year) => setSelectedYear(year);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState([{}]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertType, setAlertType] = useState<'success' | 'info' | 'warning'>('success');
  const [alertHeader, setAlertHeader] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [lineItem, setlineItem] = useState<any[]>([]);
  const [hasLineItem, setHasLineItem] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const isSavingDraftRef = useRef(isSavingDraft);
  const [diabledSumiit, setDiabledSumiit] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [quarterData, setQuarterData] = useState([
    {
      quarter: 1, rating: '', description: '', areaOfImprovement: '',
    },
    {
      quarter: 2, rating: '', description: '', areaOfImprovement: '',
    },
    {
      quarter: 3, rating: '', description: '', areaOfImprovement: '',
    },
    {
      quarter: 4, rating: '', description: '', areaOfImprovement: '',
    },
  ]);
  const naviagte = useNavigate();

  const [toastmsg, setToastMsg] = useState<ToastProps>({
    open: false,
    autoHideDuration: 9000,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: '',
  });
  // enable and dissable buttons when it have data
  const handleChange = (index: number, updatedData: any) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedData;
      return newData;
    });
  };

  const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter());

  const handleShowAlert = (
    header: string,
    message: string,
    type: 'success' | 'info' | 'warning',
  ) => {
    setAlertType(type);
    setAlertHeader(header);
    setAlertMessage(message);
    setShowAlertMessage(true);
  };

  const onDismiss = () => {
    setToastMsg({
      open: false,
      message: '',
      horizontal: 'center',
      vertical: 'top',
      severity: '',
    });
  };

  const addArtifactFormData = async (artifactFormData: any) => {
    const updatedFormData = artifactFormData?.map((formData: any) => {
      const quarter = formData.artifact_sub_category_id === 92 ? quarterData.find((q) => q.quarter === selectedQuarter)?.quarter : null;
      return {
        ...formData,
        year: selectedYear,
        files: formData?.files?.map((file: any) => ({
          ...file,
          type1: file.type,
        })),
        quarter,
      };
    });

    /* eslint-disable no-underscore-dangle */
    const editFormData = { data: { ...updatedFormData?.[0], id: undefined }, id: updatedFormData?.[0].id };

    CommHandler.request({
      url: isEditPage ? `${config.apiBaseUrl}/artifact/updateArtifact` : `${config.apiBaseUrl}/artifact`,
      method: 'POST',
      headers: getAuthHeader,
      data: isEditPage ? editFormData : updatedFormData,
    })
      .then((response: any) => {
        if (response.status === 200) {
          setSubmitEnabled(false);
          setIsLoading(false);
          handleShowAlert(
            isEditPage ? 'Update Artifacts' : 'Add Artifacts',
            isEditPage ? 'Artifact  Updated successfully!' : 'Artifact  Added successfully!',
            'success',
          );
        } else {
          setToastMsg({
            open: true,
            message: response.message,
            vertical: 'top',
            horizontal: 'center',
            severity: 'error',
          });
          setSubmitEnabled(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setToastMsg({
          open: true,
          message: err.message,
          vertical: 'top',
          horizontal: 'center',
          severity: 'error',
        });
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const loggedInUserID = localStorage.getItem('user_id');
    const department = localStorage.getItem('department');

    let activeQuarter = null;

    // Only check for active quarter if the artifactCatCode is 'FB'
    if (artifactCatCode === 'FB') {
      activeQuarter = quarterData?.find((quarter) => quarter?.rating && quarter?.rating?.trim() !== '');
      if (!activeQuarter) {
        setIsLoading(false);
        setToastMsg({
          open: true,
          message: 'Please enter rating of minimum 1 quarter ',
          vertical: 'top',
          horizontal: 'center',
          severity: 'error',
        });
        return;
      }
    }

    const updatedFormData = data?.map((item) => ({
      ...item,
      artifact_category_id: isEditPage ? item?.artifact_category_id : selectedCatId,
      department,
      user_id: loggedInUserID,
      financial_year: `${selectedYear}-${(parseInt(selectedYear, 10) + 1)}`,
      quarter: isEditPage ? (quarterData.find((q) => q.quarter === item.quarter)?.quarter || null) : (artifactCatCode === 'FB' ? activeQuarter?.quarter : null),
      ratings: isEditPage ? (quarterData.find((q) => q.quarter === item.quarter)?.rating || null) : (artifactCatCode === 'FB' ? (+activeQuarter?.rating) : null),
      description: isEditPage ? item.description : (artifactCatCode === 'FB' ? activeQuarter?.description : item.description),
      area_of_improvement: isEditPage ? (quarterData.find((q) => q.quarter === item.quarter)?.areaOfImprovement || null) : (artifactCatCode === 'FB' ? activeQuarter?.areaOfImprovement : null),
    }));

    const isFieldsValid = validateFields({
      artifactFormData: updatedFormData,
      artifactTypeName: isEditPage ? categoryName : artifactCatCode,
      hasLineItem,
      isEditable: isEditPage,
    });
    const missingFields = [];
    updatedFormData.forEach((item) => {
      if (artifactCatCode === 'FB' && (!item.description || !item.area_of_improvement)) {
        if (!item.description) missingFields.push('Description');
        if (!item.area_of_improvement) missingFields.push('Area of Improvement');
      }
    });
    if (missingFields.length > 0) {
      setIsLoading(false);
      setToastMsg({
        open: true,
        message: `Please fill all required fields: ${missingFields.join(', ')}`,
        vertical: 'top',
        horizontal: 'center',
        severity: 'error',
      });
      return;
    }
    setValidationErrors(isFieldsValid);
    if (isFieldsValid.every((obj) => Object.keys(obj).length === 0)) {
      data.forEach(async (el) => {
        const fileNames = el?.files?.filter((value:any) => !el?.preFilePath?.includes(value?.fileName))?.map((file: any) => file.fileName) || [];
        if (fileNames?.length > 0) {
          try {
            await CommHandler.post(
              `${config.apiBaseUrl}/uploadFiles/moveFiles`,
              { files: fileNames },
            );
          } catch (error) {
            // eslint-disable-next-line
            console.error('Error uploading file:', error);
            throw error;
          }
        }
      });
      await addArtifactFormData(updatedFormData);
    } else {
      setIsLoading(false);
      setToastMsg({
        open: true,
        message: 'Please fill all required fields',
        vertical: 'top',
        horizontal: 'center',
        severity: 'error',
      });
    }
  };
  const categoryCodeHandler = async (catId: string) => {
    const [categoryListResponse] = await Promise.all([
      CommHandler.request({
        url: `${config.adminApiURL}/artifact/category`,
        method: 'GET',
        headers: getAuthHeader,
      }),
    ]);
    const catName: any = categoryListResponse.data?.find(
      (cat: any) => cat.id === catId,
    );
    setCategoryName(catName?.code);
  };

  useEffect(() => {
    async function fetchData() {
      CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/getArtifact`,
        method: 'POST',
        headers: getAuthHeader,
        data: { id: artifactFormId },
      })
        .then(async (response: any) => {
          const {
            from_date: FromDate, to_date: toDate, artifact_category_id: artifactCategoryID, file_path: filePath, files, quarter, ratings, description, area_of_improvement,
          } = response?.data ?? {};
          categoryCodeHandler(artifactCategoryID);
          setData([{ ...response?.data, preFilePath: filePath, preFiles: files }]);
          setQuarterData((prev) => prev.map((q, index) => ({
            ...q,
            rating: index + 1 === quarter ? ratings : '',
            description: index + 1 === quarter ? description : '',
            areaOfImprovement: index + 1 === quarter ? area_of_improvement : '',
          })));
          setSelectedQuarter(quarter);
          setFromDate(dayjs(FromDate));
          setEndDate(dayjs(toDate));
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
        });
    }
    if (isEditPage) {
      fetchData();
    }
  }, [isEditPage, artifactFormId]);

  useEffect(() => {
    isSavingDraftRef.current = isSavingDraft;
    return () => {
      // Cleanup function to reset isSavingDraft when the component is unmounted
      setIsSavingDraft(false);
      isSavingDraftRef.current = false;
    };
  }, [isSavingDraft]);

  // const handleDraft = async () => {
  //   if (isSavingDraftRef.current) {
  //     return;
  //   }
  //   setIsSavingDraft(true);
  //   setDiabledSumiit(true);
  //   try {
  //     const saveAsDraft = true;
  //     const isFieldsValid = validateFields({
  //       artifactFormData: data,
  //       draftFlag: saveAsDraft,
  //     });
  //     setValidationErrors(isFieldsValid);
  //     if (isFieldsValid.every((obj) => Object.keys(obj).length === 0)) {
  //       const loggedInUserID = localStorage.getItem('user_id');
  //       const updatedFileData: any[] = await Promise.all(
  //         data.map(async (el) => {
  //           // const formData = new FormData();
  //           const fileNames = [];

  //           const reformattedData = el;
  //           if (el.files.length > 0) {
  //             el?.files?.filter((value:any) => !el?.preFilePath?.includes(value?.fileName))?.forEach((file: any) => {
  //               fileNames.push(file.fileName);
  //             });
  //             try {
  //               await CommHandler.post(
  //                 `${config.apiBaseUrl}/uploadFiles/moveFiles`,
  //                 { files: fileNames },
  //               );
  //             } catch (error) {
  //               // eslint-disable-next-line
  //               console.error('Error uploading file:', error);
  //               throw error;
  //             }
  //           }
  //           return reformattedData;
  //         }),
  //       );
  //       const updatedDraftFormData = updatedFileData.map((item) => ({
  //         ...item,
  //         is_draft: true,
  //         user_id: loggedInUserID,
  //         financial_year: `${selectedYear}-${(parseInt(selectedYear, 10) + 1)}`,
  //         artifact_category_id: isEditPage ? item?.artifact_category_id : selectedCatId,
  //       }));
  //       await addArtifactFormData(updatedDraftFormData);
  //     } else {
  //       setToastMsg({
  //         open: true,
  //         message: 'Please fill all required fields',
  //         vertical: 'top',
  //         horizontal: 'center',
  //         severity: 'error',
  //       });
  //     }
  //   } catch (e) {
  //     // eslint-disable-next-line
  //     console.error('Error saving draft:', e);
  //     setDiabledSumiit(false);
  //   } finally {
  //     setIsSavingDraft(false);
  //   }
  // };

  // const handleFormDelete = (index: any) => {
  //   const newData = [...data];
  //   newData.splice(index, 1);
  //   setData(newData);
  //   if (newData.length < 5) {
  //     setAddMoreHide(true);
  //     setShowAlert(false);
  //   }
  // };

  const handleCloseAlert = () => {
    if (alertType === 'success') {
      naviagte('/artifact-detail', { state: { tab: selectedCatId } });
    }
    // setShowAlert(false);
  };

  const onClose = () => {
    navigate(-1);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleConfirmCancelConfirmation = () => {
    setShowConfirmationDialog(false);
    onClose();
  };

  const handClickBack = () => {
    const hasUnsavedChanges = data?.some((formData) => Object.keys(formData)?.some((key) => {
      const value = formData[key];
      return value !== projectLevelDataInterface[key] && value !== '' && value !== null && (typeof value === 'string' || Array.isArray(value)) && value.length > 0;
    }));
    if (hasUnsavedChanges) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {toastmsg && (
      <Toast
        open={toastmsg.open}
        autoHideDuration={toastmsg.autoHideDuration}
        message={toastmsg.message}
        vertical={toastmsg.vertical}
        horizontal={toastmsg.horizontal}
        onClose={onDismiss}
      >
        <Alert
          onClose={onDismiss}
          severity={mapSeverityToAlertColor(toastmsg.severity)}
          sx={{ width: '100%' }}
        >
          {toastmsg.message}
          !
        </Alert>
      </Toast>
      )}

      <FormWrapper
        style={{
          background: 'white',
          border: '1px solid rgb(231, 231, 231)',
          borderRadius: '10px',
        }}
      >
        <div className="artifact-Level-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="title">
            <div
              onClick={handClickBack}
              style={{ cursor: 'pointer' }}
              onKeyPress={(e: { key: string }) => e.key === 'Enter' && handClickBack()}
              role="button"
              tabIndex={0}
            >
              <img
                src="/leftArrow.png"
                alt="left-arrow"
                width="16px"
                height="16px"
                style={{
                  marginRight: '12px',
                  marginTop: '5px',
                }}
              />
            </div>
            {' '}
            {isEditPage ? <h2 style={cardContentStyle}>Edit Artifact</h2> : 'Artifact Level :' }
            {' '}
            {artifactName || ''}
          </div>
          <MenuAppraisalCycle defaultSelectedYear={selectedYear} onYearSelect={handleYearSelect} />
        </div>
        <div className="level-section">
          {data.length
            ? data.map((item, index) => (
              <AddProductLevelForm
                onChange={(updatedData: any) => handleChange(index, updatedData)}
                data={item}
                index={index}
                setData={setData}
                artifactType={artifactCatCode}
                artifactCatId={artifactCatId}
                setSubmitEnabled={setSubmitEnabled}
                artifactName={artifactName}
                selectedCatId={selectedCatId}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                lineItem={lineItem}
                setlineItem={setlineItem}
                setHasLineItem={setHasLineItem}
                fromDate={fromDate}
                endDate={endDate}
                setFromDate={setFromDate}
                setEndDate={setEndDate}
                quarterData={quarterData}
                setQuarterData={setQuarterData}
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
              />
            ))
            : null}
          <div
            className="flex-row-container"
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div className="form-btn-grp">
              {/* <Button
                className="secondary-btn"
                disabled={isLoading || isSavingDraftRef.current}
                variant="outlined"
                onClick={() => handleDraft()}
                startIcon={(
                  <img
                    src="/draftImage.png"
                    alt="draft"
                    width="15px"
                    height="15px"
                  />
                  )}
              >
                Save as draft
              </Button> */}
              {/* <Button className="secondary-btn" variant="outlined" onClick={() => handlePreview()} startIcon={<img src="/view.png" alt="view" width="15px" height="15px" />}>
                Preview
              </Button> */}
              {/* {isLoading || diabledSumiit ? (
              {isLoading || diabledSumiit ? (
                <div className="">
                  <Button
                    className="addArtifact-form-btn"
                    variant="contained"
                    disabled={isLoading || diabledSumiit}
                    startIcon={
                      isLoading && (
                      <CircularProgress
                        size={20}
                        color="inherit"
                      />
                      )
                    }
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    className={`addArtifact-form-btn ${
                      !submitEnabled
                        ? 'primary-color'
                        : ''
                    }`}
                    variant="contained"
                    disabled={submitEnabled}
                    onClick={handleSubmit}
                    startIcon={(
                      <img
                        src="/submit1.png"
                        alt="submit button"
                        width="15px"
                        height="15px"
                      />
                      )}
                  >
                    Submit
                  </Button>
                </div>
              )} */}
              <SubmitButton
                isLoading={isLoading}
                disabledSubmit={diabledSumiit}
                submitEnabled={submitEnabled}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
        <CancelConfirmDialoge showConfirmationDialog={showConfirmationDialog} handleCancelConfirmation={handleCancelConfirmation} handleConfirmCancelConfirmation={handleConfirmCancelConfirmation} />

        <MessageAlert
          open={showAlertMessage}
          onClose={handleCloseAlert}
          header={alertHeader || 'Alert'}
          message={alertMessage || 'An error occurred.'}
          alertType={alertType}
        />
      </FormWrapper>
    </>
  );
};

export default AddProjectLevelArtifact;
